import * as React from "react"
import Button from "../Button/Button"
import styled from "styled-components"

const FlexContainer = styled.div`
    display: flex;
    gap: 0 var(--space-9);
    flex-wrap: wrap;
`

const StyledForm = styled.form`
    flex-basis: 600px;
    flex-grow: 0;

    input, textarea, select {
        display: block;
        width: 100%;
        padding: var(--fixed-space-2);
        background-color: var(--secondary-100);
        border: 1px solid var(--secondary-50);
        border-radius: 0;
        -webkit-appearance: none;
        color: var(--neutral-800);
        box-shadow: ;
    }

    textarea {
        resize: none;
    }
`

const FieldGroup = styled.div`
    margin-bottom: var(--space-2);
`

const LabelGroup = styled.span`
    display: flex;
    justify-content: space-between;
`

const Legend = styled.legend`
    font-size: var(--type-s);
`

const FieldSet = styled.fieldset`
    border: none;
    padding: 0;
    margin-bottom: var(--space-2);
`

const SelectWrapper = styled.div`
    position: relative;

    &::after {
        content: "▼";
        position: absolute;
        top: 9px;
        right: 12px;
        color: var(--neutral-800);
    }
`

const Sidebar = styled.div`
    flex-basis: 300px;
    flex-shrink: 0;
    flex-grow: 1;
    padding-top: var(--space-7);
`

const PhoneHeading = styled.p`
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
    color: var(--secondary-300);
    margin-bottom: 0;
`

const PhoneNumber = styled.a`
    font-weight: 700;
    font-size: var(--type-l);
    color: var(--primary-600);
`

function Form() {
    return (
        <FlexContainer>
            <StyledForm method="post" action="/success" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <h3>Send a message</h3>
                <p>I aim to respond to all enquiries within a few hours but you should always get a reply within 1 working day.</p>
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <FieldSet>
                    <Legend as="h3">Your Info</Legend>
                    <FieldGroup>
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" required />
                    </FieldGroup>
                    <FieldGroup>
                        <label htmlFor="email">Email address</label>
                        <input type="email" name="email" id="email" required />
                    </FieldGroup>
                    <FieldGroup>
                        <LabelGroup>
                            <label htmlFor="phone">Telephone number</label>
                            <label htmlFor="phone">(Optional)</label>
                        </LabelGroup>
                        <input type="tel" name="phone" id="phone" />
                    </FieldGroup>
                </FieldSet>
                <FieldSet>
                    <Legend as="h3">Your Company</Legend>
                    <FieldGroup>
                        <label htmlFor="company">Company name</label>
                        <input type="text" name="company" id="company" required />
                    </FieldGroup>
                    <FieldGroup>
                        <LabelGroup>
                            <label htmlFor="website">Website URL</label>
                            <label htmlFor="website">(Optional)</label>
                        </LabelGroup>
                        <input type="url" name="website" id="website" placeholder="e.g. https://www.example.com" />
                        <small>Make sure to include the 'https://' part of the URL.</small>
                    </FieldGroup>
                </FieldSet>
                <FieldSet>
                    <Legend as="h3">Your Enquiry</Legend>
                    <FieldGroup>
                        <label htmlFor="message">Message</label>
                        <textarea rows="6" name="message" id="message" placeholder="If enquiring about a project, please provide some details - e.g. services, features, timescales." required />
                    </FieldGroup>
                    <FieldGroup>
                        <LabelGroup>
                            <label htmlFor="budget">Project budget (£)</label>
                            <label htmlFor="budget">(Optional)</label>
                        </LabelGroup>
                        <SelectWrapper>
                            <select name="budget" id="budget">
                                <option value="">Please choose an option</option>
                                <option value="500max">Up to £500</option>
                                <option value="1000max">£501 - £1,000</option>
                                <option value="2500max">£1,001 - £2,500</option>
                                <option value="5000max">£2,501 - £5,000</option>
                                <option value="10000max">£5,001 - £10,000</option>
                                <option value="10000min">More than £10,000</option>
                            </select>
                        </SelectWrapper>
                    </FieldGroup>
                </FieldSet>
                <Button fullWidth={true} type="submit">Send your message</Button>
            </StyledForm>
            <Sidebar>
                <p>If you'd prefer to talk over the phone, you can call the following number or send a message with some times to schedule a call.</p>
                <PhoneHeading>Telephone</PhoneHeading>
                <PhoneNumber href="tel:+447540937646">07540 937 646</PhoneNumber>
            </Sidebar>
        </FlexContainer>
    )
}

export default Form
import { graphql } from "gatsby"
import * as React from "react"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout/Layout"
import TxtImg from "../components/TxtImg/TxtImg"
import Form from "../components/Form/Form"
import { Helmet } from "react-helmet"

function Contact({ data }) {
    const contactImage = getImage(data.contactImage)

    return (
        <Layout pageTitle="Contact">
            <Helmet>
                <meta name="description" content="Let's start a conversation. Working together, we can create an outstanding website for your business and unlock your potential on the web. Get in touch today and let's get the conversation started." />
            
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://goddard.digital/contact/" />
                <meta property="og:title" content="Contact | Goddard Digital - Websites that deliver results" />
                <meta property="og:description" content="Let's start a conversation. Working together, we can create an outstanding website for your business and unlock your potential on the web. Get in touch today and let's get the conversation started." />
                <meta property="og:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://goddard.digital/contact/" />
                <meta name="twitter:title" content="Contact | Goddard Digital - Websites that deliver results" />
                <meta name="twitter:description" content="Let's start a conversation. Working together, we can create an outstanding website for your business and unlock your potential on the web. Get in touch today and let's get the conversation started." />
                <meta name="twitter:image" content="https://goddard.digital/static/web-devices-f440bf3461d2b3db58cdbee68a4498a0.png" />
            </Helmet>
            <section>
                <div style={{width: 'min(90%, 1200px)', margin: 'auto'}}>
                    <TxtImg
                        image={contactImage}
                        alt="Contact us illustration"
                    >
                        <h2>Let's start a conversation.</h2>
                        <p>Working together, we can create an outstanding website for your business and unlock your potential on the web. Get in touch today and let's get the conversation started.</p>
                    </TxtImg>
                </div>
            </section>
            <section>
                <div style={{width: 'min(90%, 1200px)', margin: 'auto'}}>
                    <Form />
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        contactImage: file(relativePath: {eq: "contact-us.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 1000
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Contact
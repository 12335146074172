import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledButton = styled.button`
    padding: var(--fixed-space-3) var(--fixed-space-5);
    display: inline-block;
    width: ${props => props.fullWidth ? "100%" : "initial"};
    background-color: var(--primary-900);
    color: white;
    margin: var(--space-1) 0;
    box-shadow: var(--shadow-elevation-low);
    transition: all 300ms;
    border: none;
    font-size: var(--type-xs);

    :hover {
        box-shadow: var(--shadow-elevation-medium);
        background-color: var(--primary-700);
        transition: all 300ms;
        cursor: pointer;
    }

    :active {
        box-shadow: var(--shadow-elevation-low);
    }
`

function Button(props) {
    if (props.link) {
        return (
            <StyledButton fullWidth={props.fullWidth} as={Link} to={props.linkTo}>{props.children}</StyledButton>
        )
    } else if (props.externalLink) {
        return (
            <StyledButton fullWidth={props.fullWidth} as="a" href={props.linkTo} target="_blank">{props.children}</StyledButton>
        )
    } else {
        return (
            <StyledButton fullWidth={props.fullWidth}>{props.children}</StyledButton>
        )
    }
}

export default Button